import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import type { BaseContext, File } from '@/types';
import Api from '@/services/api';

export type FilterRequest = {
	limit: number;
	offset: number;
	line_id?: string;
	task_id?: string;
	sector_id?: string;
	report_name?: string;
	workstation_id?: string;
	created_at_end?: string;
	organization_id?: string;
	created_at_start?: string;
	company_id_filter?: string;
	searching_organization_id?: string;
	order_option?: string;
	order_by_name?: string;
	order_by_uploader?: string;
	status?: 'CORRUPTED_FILE' | 'EXTRACTED_DATA' | 'NOT_PROCESSED' | 'PROCESSING' | 'PROCESSED' | 'IN_QUEUE';
	exclude_tool?: {
		name: string;
		id?: string;
	};
};

export type FileListRequest = BaseContext & {
	filter?: FilterRequest;
};

export type FileResponse = Pick<
	File,
	'id' | 'original_name' | 'duration' | 'size' | 'status' | 'createdAt' | 'updatedAt' | 'thumbnail'
>;

export type FilesListResponse = FileResponse & {
	company_id: string;
	organization_id: string;
	workstation_id: string;
	sector_id: string;
	organization?: {
		id: string;
		name: string;
	};
	user?: {
		name: string;
	};
	company?: {
		id: string;
		name: string;
	};
	sector: {
		id: string;
		name: string;
	};
	workstations: {
		id: string;
		name: string;
		line: {
			id: string;
			name: string;
		};
	};
	preliminary_analysis: {
		worst_score: number;
		consolidated: boolean;
	};
};

export type FileListDTO = {
	count: number;
	rows: FilesListResponse[];
};

type FileItem = Omit<FilesListResponse, 'preliminary_analysis'> & {
	custom_report_result: {
		worst_score: number;
		consolidated: boolean;
	};
};

type GetFileListResponseDTO = {
	count: number;
	rows: FileItem[];
};

async function getFileSummaryList({
	organization_id,
	company_id,
	filter
}: FileListRequest): Promise<GetFileListResponseDTO> {
	const url = `/file`;
	const params = { organization_id, company_id, ...filter };
	const { data } = await Api.get<GetFileListResponseDTO>(url, { params });
	return data;
}

export function useListFiles(params: FileListRequest) {
	const summaryList = useQuery([QUERY_KEYS.GET_FILES_LIST, params], () => getFileSummaryList(params), {
		enabled: !!params.organization_id && !!params.company_id,
		select: (data) => ({
			...data,
			rows: data.rows.map((row) => ({
				...row,
				preliminary_analysis: row.custom_report_result
			}))
		})
	});
	return {
		...summaryList,
		data: summaryList.data ?? ({} as FileListDTO)
	};
}
