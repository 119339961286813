import { EnvironmentPath, EnvironmentPathParameters } from '@/types';

const config: EnvironmentPath = {
	production: {
		path: 'seguros-bolivar',
		api: {
			url: 'https://api-homolog.kinebot.com.br/seguros-bolivar',
			ws: 'https://api-homolog.kinebot.com.br'
		}
	},
	homolog: {
		path: '',
		api: {
			url: '',
			ws: ''
		}
	},
	development: {
		path: 'seguros-bolivar',
		api: {
			url: 'http://localhost:4000/seguros-bolivar',
			ws: 'http://localhost:4000'
		}
	}
};

export default config[process.env.REACT_APP_NODE_ENV] as EnvironmentPathParameters;
